.fade-enter {
  transform: translateX(-50%);
  opacity: 0;
}
.fade-exit {
  transform: translateX(0%);
  opacity: 1;
}
.fade-enter-active {
  transform: translateX(0%);
  opacity: 1;
}
.fade-exit-active {
  transform: translateX(50%);
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms, transform 10ms;
}
